@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/* purgecss start ignore */
html,
body {
  /* font-family: 'Lato'; */
  background-color: #f8f9fe;
  color: "#333333";
}

@layer components {
  .btn-primary {
    @apply bg-primary-500 text-white;
  }

  .btn-secondary {
    @apply border border-primary-500 text-primary-500;
  }

  .dropdown-item {
    @apply w-[30rem] px-4 py-2 flex items-center text-text-black space-x-2 justify-around border-y border-gray-300;
  }

  .dropdown-header {
    @apply w-[30rem] px-4 py-3 flex items-center justify-end text-text-black border-b border-gray-300 text-sm space-x-3;
  }

  .inputs-container {
    @apply flex flex-col md:flex-row gap-x-8;
  }
}

/* custom spinner */

.spinner {
  animation: spin 1s ease infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* remove input border, no se quitaba con tw */
input:hover,
input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

/* carousel */
.carousel-img {
  transition: 1s;
}

.carousel-present {
  left: 0;
  opacity: 1;
  visibility: visible;
  position: absolute;
}

.carousel-next {
  position: absolute;
  left: 0vw;

  opacity: 0;
  visibility: hidden;
  animation: fadeIn 5s;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #dedede;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #097dc1;
  border-radius: 14px;
}

#sidebar-scrollbar ::-webkit-scrollbar {
  display: none;
} /* FIXME idk */

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #5145cd;
  position: fixed;
  z-index: 1060;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #5145cd,
    0 0 5px #5145cd;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #5145cd;
  border-left-color: #5145cd;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* purgecss end ignore */

.slider {
  --SliderColor: #097dc1;
  -webkit-appearance: none;
}

/* --------------------------- webkit browsers */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: var(--SliderColor);
  overflow: visible;
  cursor: pointer;
}

/* -------------------------- Firefox */
.slider::-moz-range-thumb {
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: var(--SliderColor);
  overflow: visible;
  cursor: pointer;
}

.slider::-moz-focus-outer {
  border: 0;
}
